import axios from '@axios'

export default {
    namespaced: true,
    state: {
        memberOptions: [],
        selectedMembers: [],
    },
    getters: {},
    mutations: {
        SET_SELECTED_EVENTS(state, val) {
            state.selectedMembers = val
        },
    },
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/meetings', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/meetings/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/meetings/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/meetings', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/meetings', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/meetings/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/meetings/cancel', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        shareItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/meetings/submitForApproval/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        reportItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/meetings/report', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        reportShareItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/meetings/report-share/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addDocument(ctx, {meetingId, dataInfo}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/meetings/document-add/${meetingId}`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
