<template>
  <div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-row class="pb-1">
          <b-col cols="12" md="8" lg="8">
            <b-card no-body class="p-1 mb-0">
              <div class="d-flex justify-content-between align-items-start">
                <div class="d-flex">
                  <feather-icon icon="CalendarIcon" size="19"/>
                  <h5 class="mb-0 ml-50">Toplantı Bilgileri</h5>
                </div>
                <b-button-toolbar justify>
                  <b-button-group>
                    <b-button variant="primary" type="submit" size="sm" v-if="editDataForm">
                      <span class="align-middle" role="button"> Kaydet ve Paylaş</span>
                    </b-button>
                    <b-button variant="outline-danger" size="sm" @click="eventCancel" v-if="editDataForm && dataInfo.id > 0">
                      <span class="align-middle" role="button"> İptal Et</span>
                    </b-button>
                    <b-button variant="outline-primary" size="sm" :to="{ name: 'meeting-report', params: {id: dataInfo.id} }" v-if="reportDataForm && dataInfo.id > 0">
                      <span class="align-middle" role="button"> Tutanak</span>
                    </b-button>
                    <b-button variant="outline-info" size="sm" @click="documentModalShow=true" v-if="dataInfo.id > 0">
                      <span class="align-middle" role="button"> Dökümanlar</span>
                    </b-button>
                    <b-button :to="{ name: 'meeting-list' }" variant="outline-secondary" size="sm">
                      <span class="align-middle" role="button"> {{ editDataForm ? 'Vazgeç' : 'Kapat' }}</span>
                    </b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
              <b-row class="mt-1 mb-0">
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Konu" rules="required">
                    <b-form-group label="* Konu" :state="getValidationState(validationContext)">
                      <b-form-input trim placeholder="Konu" v-model="dataInfo.title" :disabled="!editDataForm"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Kategori" rules="required">
                    <b-form-group label="* Kategori" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.meetingCategoryId" :options="categoryOptions" :reduce="val => val.value" :clearable="false" :disabled="!editDataForm"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Başlangıç Zamanı" rules="required">
                    <b-form-group label="* Başlangıç Zamanı" :state="getValidationState(validationContext)">
                      <flatpickr v-on:input="getRooms" v-model="dataInfo.startDate" class="form-control" :config="{enableTime: true, defaultHour: 8, defaultMinute: 0, minuteIncrement: 15, altInput: true, altFormat: 'd.m.Y H:i', dateFormat: 'Y-m-d H:i', time_24hr: true, wrap: true, locale: { firstDayOfWeek: 1}, weekStart: 1}" :disabled="!editDataForm"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Bitiş Zamanı" rules="required">
                    <b-form-group label="* Bitiş Zamanı" :state="getValidationState(validationContext)">
                      <flatpickr v-model="dataInfo.endDate" class="form-control" :config="{enableTime: true, defaultHour: 18, defaultMinute: 0, minuteIncrement: 15, altInput: true, altFormat: 'd.m.Y H:i', dateFormat: 'Y-m-d H:i', time_24hr: true, wrap: true, locale: { firstDayOfWeek: 1}, weekStart: 1}" :disabled="!editDataForm"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="İşyeri" rules="required">
                    <b-form-group label="* İşyeri" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.workplaceId" :options="workplaceOptions" :reduce="val => val.value" :clearable="false" v-on:input="getRooms" :disabled="!editDataForm"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Toplantı Salonu" rules="required">
                    <b-form-group label="* Toplantı Salonu" :state="getValidationState(validationContext)">

                      <v-select v-model="dataInfo.meetingRoomId" :clearable="false" :options="roomOptions" label="label" :reduce="val => val.value" :disabled="!editDataForm">
                        <template #option="{ busyRoom, label }">
                          <span class="ml-50 align-middle"> {{ label }} </span>
                          <span class="align-middle" v-if="busyRoom"> - </span>
                          <feather-icon icon="AlertTriangleIcon" size="16" v-if="busyRoom"/>
                          <span class="align-middle" v-if="busyRoom"> Meşgul </span>
                        </template>
                        <template #selected-option="{ label }">
                          <span class="ml-50 align-middle"> {{ label }}</span>
                        </template>
                      </v-select>

                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Toplantı Tipi" rules="required">
                    <b-form-group label="* Toplantı Tipi" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.meetingTypeId" :options="meetingTypeOptions" :reduce="val => val.value" :clearable="false" :disabled="!editDataForm"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Hatırlatma" rules="required">
                    <b-form-group label="* Hatırlatma" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.meetingRemindingTypeId" :options="meetingRemindingTypeOptions" :reduce="val => val.value" :clearable="false" :disabled="!editDataForm"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="12" lg="12" v-if="editDataForm">
                  <validation-provider #default="validationContext" name="Döküman Ekle">
                    <b-form-group label="Döküman Ekle">
                      <b-form-file id="meetingFiles" name="meetingFiles" placeholder="Döküman seçimi yapınız..." drop-placeholder="Dökümanlarınızı sürükleyerek buraya bırakabilirsiniz..." multiple/>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="12" lg="12">
                  <validation-provider #default="validationContext" name="Toplantı İçeriği">
                    <b-form-group label="Toplantı İçeriği">
                      <b-form-textarea v-model="dataInfo.description" rows="6" placeholder="Toplantı İçeriği..." :disabled="!editDataForm"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <b-card no-body class="p-1 h-100 scroll-flow">
              <div class="d-flex justify-content-between align-items-start mb-1">
                <div class="d-flex">
                  <feather-icon icon="GitMergeIcon" size="16"/>
                  <h5 class="mb-0 ml-50">Toplantı Akışı</h5>
                </div>
                <b-button variant="primary" size="sm" @click="flowNewModal" v-if="editDataForm">
                  <feather-icon icon="PlusSquareIcon" size="15"/>
                </b-button>
              </div>
              <b-list-group v-if="flowList.length > 0">
                <b-list-group-item v-for="(flow, index) in flowList" :key="index">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex justify-content-start align-items-center">
                      <b-avatar class="mr-1" :text="flow.sortNumber.toString()" :variant="`light-primary`" size="35"/>
                      <div class="profile-user-info">
                        <h6 class="mb-0">{{ flow.title }}</h6>
                        <div class="d-flex justify-content-start align-items-start flex-column">
                          <small class="text-muted">{{ flow.startDate }} - {{ flow.endDate }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="button-group">
                      <b-button variant="gradient-warning" class="btn-icon mr-1" size="sm" @click="flowEdit(flow)" v-if="editDataForm">
                        <feather-icon size="8" icon="EditIcon"/>
                      </b-button>
                      <b-button variant="gradient-danger" class="btn-icon" size="sm" @click="flowRemove(flow)" v-if="editDataForm">
                        <feather-icon size="8" icon="XIcon"/>
                      </b-button>
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
              <div class="empty-card border h-100 py-1" v-if="flowList.length === 0">
                <b-media no-body>
                  <b-media-aside class="mb-1">
                    <b-avatar size="70" variant="light-warning">
                      <feather-icon size="30" icon="GitMergeIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                  </b-media-body>
                </b-media>
                <b-card-text class="font-small-3 mb-0">
                  Henüz akış eklenmemiş...
                </b-card-text>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="pb-1">
          <b-col cols="12" md="4" lg="4">
            <b-card no-body class="h-100 p-1 mb-0 scroll-flow">
              <div class="d-flex">
                <feather-icon icon="UsersIcon" size="16"/>
                <h5 class="mb-0 ml-50">Katılımcılar</h5>
              </div>
              <div class="d-flex justify-content-between mb-1 mt-1 mr-5">
                <v-select v-model="newParticipantUserId" :clearable="false" :options="userOptions" label="label" :reduce="val => val.value" class="select-size-sm" v-if="editDataForm">
                  <template #option="{ avatar, label }">
                    <b-avatar size="sm" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                    <span class="ml-50 align-middle"> {{ label }}</span>
                  </template>
                  <template #selected-option="{ avatar, label }">
                    <b-avatar size="sm" class="border border-white" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                    <span class="ml-50 align-middle"> {{ label }}</span>
                  </template>
                </v-select>
                <b-button variant="primary" size="sm" class="ml-1" @click="addParticipant('PARTICIPANT')" v-if="editDataForm">
                  <feather-icon icon="PlusSquareIcon" size="16"/>
                </b-button>
              </div>
              <b-list-group v-if="participantUsers.length > 0">
                <b-list-group-item v-for="(user, index) in participantUsers" :key="index">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex justify-content-start align-items-center">
                      <b-avatar class="mr-1" :src="getApiFile(user.profilePicture)" :text="avatarText(user.firstName + ' ' + user.lastName)" :variant="`light-primary`" size="35"/>
                      <div class="profile-user-info">
                        <h6 class="mb-0">{{ user.firstName + ' ' + user.lastName }}</h6>
                        <div class="d-flex justify-content-start align-items-start flex-column">
                          <small class="text-muted">{{ user.title }}</small>
                        </div>
                        <div class="my-auto" v-if="user.meetingParticipantTypeId === 'ORGANIZER'">
                          <b-badge variant="light-info">Moderatör</b-badge>
                        </div>
                      </div>
                    </div>
                    <div>
                      <b-button variant="gradient-info" class="btn-icon mr-1" size="sm" @click="setOrganizer(user)" v-if="editDataForm">M</b-button>
                      <b-button variant="gradient-danger" class="btn-icon" size="sm" @click="removeParticipant(user)" v-if="editDataForm">X</b-button>
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
              <div class="empty-card border h-100 py-1" v-if="participantUsers.length === 0">
                <b-media no-body>
                  <b-media-aside class="mb-1">
                    <b-avatar size="70" variant="light-warning">
                      <feather-icon size="30" icon="UsersIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                  </b-media-body>
                </b-media>
                <b-card-text class="font-small-3 mb-0">
                  Henüz kullanıcı eklenmemiş...
                </b-card-text>
              </div>
            </b-card>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <b-card no-body class="h-100 p-1 mb-0 scroll-flow">
              <div class="d-flex">
                <feather-icon icon="UsersIcon" size="16"/>
                <h5 class="mb-0 ml-50">Bilgililer</h5>
              </div>
              <div class="d-flex justify-content-between mb-1 mt-1 mr-5">
                <v-select v-model="newInfoUserId" :clearable="false" :options="userOptions" label="label" :reduce="val => val.value" class="select-size-sm" v-if="editDataForm">
                  <template #option="{ avatar, label }">
                    <b-avatar size="sm" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                    <span class="ml-50 align-middle"> {{ label }}</span>
                  </template>
                  <template #selected-option="{ avatar, label }">
                    <b-avatar size="sm" class="border border-white" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                    <span class="ml-50 align-middle"> {{ label }}</span>
                  </template>
                </v-select>
                <b-button variant="primary" size="sm" class="ml-1" @click="addParticipant('INFORMATION')" v-if="editDataForm">
                  <feather-icon icon="PlusSquareIcon" size="16"/>
                </b-button>
              </div>
              <b-list-group v-if="informationUsers.length > 0">
                <b-list-group-item v-for="(user, index) in informationUsers" :key="index">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex justify-content-start align-items-center">
                      <b-avatar class="mr-1" :src="getApiFile(user.profilePicture)" :text="avatarText(user.firstName + ' ' + user.lastName)" :variant="`light-primary`" size="35"/>
                      <div class="profile-user-info">
                        <h6 class="mb-0">{{ user.firstName + ' ' + user.lastName }}</h6>
                        <div class="d-flex justify-content-start align-items-start flex-column">
                          <small class="text-muted">{{ user.title }}</small>
                        </div>
                      </div>
                    </div>
                    <b-button variant="gradient-danger" class="btn-icon" size="sm" @click="removeParticipant(user)" v-if="editDataForm">X</b-button>
                  </div>
                </b-list-group-item>
              </b-list-group>
              <div class="empty-card border h-100 py-1" v-if="informationUsers.length === 0">
                <b-media no-body>
                  <b-media-aside class="mb-1">
                    <b-avatar size="70" variant="light-warning">
                      <feather-icon size="30" icon="UsersIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                  </b-media-body>
                </b-media>
                <b-card-text class="font-small-3 mb-0">
                  Henüz kullanıcı eklenmemiş...
                </b-card-text>
              </div>
            </b-card>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <b-card no-body class="h-100 p-1 mb-0 scroll-flow">
              <div class="d-flex justify-content-between align-items-start mb-1">
                <div class="d-flex">
                  <feather-icon icon="UsersIcon" size="16"/>
                  <h5 class="mb-0 ml-50">Misafirler</h5>
                </div>
                <b-button variant="primary" size="sm" @click="guestNewModal" v-if="editDataForm">
                  <feather-icon icon="PlusSquareIcon" size="15"/>
                </b-button>
              </div>
              <b-list-group v-if="guestUsers.length > 0">
                <b-list-group-item v-for="(user, index) in guestUsers" :key="index">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex justify-content-start align-items-center">
                      <b-avatar class="mr-1" :text="avatarText(user.firstName + ' ' + user.lastName)" :variant="`light-primary`" size="35"/>
                      <div class="profile-user-info">
                        <h6 class="mb-0">{{ user.firstName + ' ' + user.lastName }}</h6>
                        <div class="d-flex justify-content-start align-items-start flex-column">
                          <small class="text-muted">{{ user.title }}</small>
                        </div>
                      </div>
                    </div>
                    <b-button variant="gradient-danger" class="btn-icon" size="sm" @click="removeParticipant(user)" v-if="editDataForm">X</b-button>
                  </div>
                </b-list-group-item>
              </b-list-group>
              <div class="empty-card border h-100 py-1" v-if="guestUsers.length === 0">
                <b-media no-body>
                  <b-media-aside class="mb-1">
                    <b-avatar size="70" variant="light-warning">
                      <feather-icon size="30" icon="UsersIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                  </b-media-body>
                </b-media>
                <b-card-text class="font-small-3 mb-0">
                  Henüz kullanıcı eklenmemiş...
                </b-card-text>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
    <b-modal v-model="guestModalShow" title="Misafir Bilgileri" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @show="guestResetModal" @hidden="guestResetModal" @ok="guestHandleModal" no-close-on-esc no-close-on-backdrop>
      <form ref="form" @submit.stop.prevent="guestSubmitModal">
        <b-form-group label="* İsim">
          <b-form-input trim placeholder="İsim" v-model="guestInfo.firstName"/>
        </b-form-group>
        <b-form-group label="* Soyisim">
          <b-form-input trim placeholder="Soyisim" v-model="guestInfo.lastName"/>
        </b-form-group>
        <b-form-group label="* Ünvan">
          <b-form-input trim placeholder="Ünvan" v-model="guestInfo.title"/>
        </b-form-group>
        <b-form-group label="Şirket">
          <b-form-input trim placeholder="Şirket" v-model="guestInfo.company"/>
        </b-form-group>
        <b-form-group label="Telefon">
          <b-form-input trim placeholder="Telefon" v-model="guestInfo.phone"/>
        </b-form-group>
        <b-form-group label="Email Adres">
          <b-form-input trim placeholder="Email Adres" v-model="guestInfo.eMail"/>
        </b-form-group>
      </form>
    </b-modal>
    <b-modal v-model="flowModalShow" title="Akış Bilgileri" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @show="flowResetModal" @hidden="flowResetModal" @ok="flowHandleModal" no-close-on-esc no-close-on-backdrop>
      <form ref="form" @submit.stop.prevent="flowSubmitModal">
        <b-form-group label="* Sıra No">
          <b-form-input trim placeholder="Sıra No" v-model="flowInfo.sortNumber"/>
        </b-form-group>
        <b-form-group label="* Başlık">
          <b-form-input trim placeholder="Başlık" v-model="flowInfo.title"/>
        </b-form-group>
        <b-form-group label="* Başlangıç Saati">
          <flatpickr v-model="flowInfo.startDate" class="form-control" :config="{enableTime: true, noCalendar: true, defaultHour: 8, defaultMinute: 0, minuteIncrement: 5, altInput: true, altFormat: 'H:i', dateFormat: 'H:i', time_24hr: true, wrap: true, locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
        </b-form-group>
        <b-form-group label="* Bitiş Saati">
          <flatpickr v-model="flowInfo.endDate" class="form-control" :config="{enableTime: true, noCalendar: true, defaultHour: 18, defaultMinute: 0, minuteIncrement: 5, altInput: true, altFormat: 'H:i', dateFormat: 'H:i', time_24hr: true, wrap: true, locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
        </b-form-group>
        <b-form-group label="Konuşmacı">
          <v-select v-model="flowInfo.userInfo" :clearable="false" :options="dataInfo.participants" item label="fullName" :reduce="val => val" class="select-size-sm">
            <template #option="{ profilePicture, fullName }">
              <b-avatar size="sm" :src="getApiFile(profilePicture)" :text="(fullName != null && !busy ? avatarText(fullName) : '')"/>
              <span class="ml-50 align-middle"> {{ fullName }}</span>
            </template>
            <template #selected-option="{ profilePicture, fullName }">
              <b-avatar size="sm" class="border border-white" :src="getApiFile(profilePicture)" :text="(fullName != null && !busy ? avatarText(fullName) : '')"/>
              <span class="ml-50 align-middle"> {{ fullName }}</span>
            </template>
          </v-select>
        </b-form-group>
      </form>
    </b-modal>
    <b-modal v-model="cancelModalShow" ref="cancel-modal" title="İptal Et" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @ok="cancelHandleModal" centered no-close-on-backdrop no-close-on-esc>
      <b-form @submit.stop.prevent="cancelSubmitModal" ref="refFormObserver">
        <b-form-group label="* İptal Açıklaması">
          <b-form-textarea trim placeholder="Açıklama" v-model="cancelInfo.description" rows="3"/>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal v-model="documentModalShow" ref="document-modal" title="Dökümanlar" ok-title="Kapat" size="lg" @ok="documentHandleModal" centered no-close-on-backdrop no-close-on-esc ok-only>
      <b-table
          ref="refListTable"
          primary-key="id"
          empty-text="Kayıt bulunamadı"
          :class="`position-relative table-sticky`"
          :items="dataInfo.documents"
          :fields="documentTableColumns"
          responsive striped bordered hover show-empty>

        <template #cell(filePath)="data">
          <div class="text-nowrap">
            <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewFile(data.value)" class="mx-1"/>
          </div>
        </template>

        <template #cell(createdOn)="data">
          <date-time-column-table :data="data.value"></date-time-column-table>
        </template>

        <template #cell(isReport)="data">
          <boolean-column-table :data="data.value"></boolean-column-table>
        </template>

      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {BAvatar, BBadge, BButton, BButtonGroup, BButtonToolbar, BCard, BCardText, BCol, BDropdown, BDropdownItem, BForm, BFormFile, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTags, BFormTextarea, BFormTimepicker, BListGroup, BListGroupItem, BMedia, BMediaAside, BMediaBody, BModal, BRow, BTable, BTooltip} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {email, required} from '@validations'
import {computed, onUnmounted, ref, watch} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/meeting/meeting/store"
import workplaceModule from "@/views/organization/workplace/store"
import categoryModule from "@/views/meeting/category/store"
import roomModule from "@/views/meeting/room/store"
import definitionModule from "@/views/system/definition/store"
import userModule from "@/views/organization/employee/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {toastMessage} from "@core/utils/utils"
import Vue from "vue"
import flatpickr from "vue-flatpickr-component/src/component"
import {BooleanColumnTable, DateColumnTable, IdColumnTable, RemoveButtonTable} from "@/components/Table";
import DateTimeColumnTable from "@/components/Table/DateTimeColumnTable.vue";

export default {
  components: {
    BooleanColumnTable,
    DateColumnTable, IdColumnTable, DateTimeColumnTable, RemoveButtonTable, BTooltip,
    BDropdownItem, BButtonGroup, BDropdown, BButtonToolbar,
    BFormTimepicker,
    BBadge,
    BFormFile,
    BCardText,
    BMediaBody,
    BMediaAside,
    BMedia,
    BListGroupItem,
    BListGroup,
    BFormTags,
    BAvatar,
    BModal,
    BTable,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    flatpickr,
    ActionButtons,
    Overlay,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_WORKPLACE_MODULE_NAME = 'store-workplace'
    const STORE_CATEGORY_MODULE_NAME = 'store-category'
    const STORE_ROOM_MODULE_NAME = 'store-room'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'
    const STORE_USER_MODULE_NAME = 'store-user'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_WORKPLACE_MODULE_NAME, workplaceModule)
      store.registerModule(STORE_CATEGORY_MODULE_NAME, categoryModule)
      store.registerModule(STORE_ROOM_MODULE_NAME, roomModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
      store.registerModule(STORE_USER_MODULE_NAME, userModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_WORKPLACE_MODULE_NAME)
        store.unregisterModule(STORE_CATEGORY_MODULE_NAME)
        store.unregisterModule(STORE_ROOM_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
        store.unregisterModule(STORE_USER_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const newParticipantUserId = ref(null)
    const newInfoUserId = ref(null)
    const editDataForm = ref(true)
    const reportDataForm = ref(false)
    const documentModalShow = ref(false)
    const documentTableColumns = ref([])

    const dataInfo = ref({
      id: null,
      meetingCategoryId: null,
      title: '',
      description: '',
      startDate: null,
      endDate: null,
      workplaceId: null,
      meetingRoomId: null,
      meetingStatusId: null,
      meetingTypeId: null,
      meetingRemindingTypeId: null,
      participants: [],
      flows: []
    })

    const meetingTypeOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'MEETING_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        meetingTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.meetingTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const meetingRemindingTypeOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'MEETING_REMINDING_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        meetingRemindingTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.meetingRemindingTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const workplaceOptions = ref([])
    busy.value = true
    store.dispatch('store-workplace/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          workplaceOptions.value.push({label: value.shortTitle, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const categoryOptions = ref([])
    busy.value = true
    store.dispatch('store-category/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          categoryOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const userOptions = ref([])
    busy.value = true
    store.dispatch('store-user/fetchItems', {sortColumn: 'firstName', sortDesc: false, isActive: true}).then(response => {
      response.data.data.forEach((value, index) => {
        if (!userOptions.value.some(e => e.value === value.id)) {
          userOptions.value.push({label: value.firstName + ' ' + value.lastName, value: value.id, avatar: value.profilePicture, positionName: value.positionName, firstName: value.firstName, lastName: value.lastName})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        if (editDataForm.value && document.getElementById('meetingFiles').files.length > 0) {
          const documents = document.getElementById('meetingFiles').files
          const formData = new FormData();
          formData.append('isReport', false);

          for (let i = 0; i < documents.length; i++) {
            let file = documents[i];
            formData.append('documents[' + i + ']', file);
          }

          store.dispatch('store/addDocument', {meetingId: response.data.data, dataInfo: formData}).then(response => {
            toastMessage(toast, 'success', response.data.message)
            router.push({name: 'meeting-list'})
          }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
          }).finally(message => {
            busy.value = false
          })

        } else {
          toastMessage(toast, 'success', response.data.message)
          router.push({name: 'meeting-list'})
        }
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const guestInfo = ref({
      id: 0,
      firstName: '',
      lastName: '',
      title: '',
      company: '',
      phone: '',
      eMail: '',
      isDeleted: false
    })

    const guestModalShow = ref(false)

    const guestNewModal = () => {
      guestInfo.value = {
        id: 0,
        firstName: '',
        lastName: '',
        title: '',
        company: '',
        phone: '',
        eMail: '',
        index: null,
        isDeleted: false
      }

      guestModalShow.value = true
    }

    const guestResetModal = () => {

    }

    const guestSubmitModal = () => {
      dataInfo.value.participants.push({
        meetingParticipantTypeId: 'GUEST',
        userId: null,
        id: guestInfo.value.id,
        firstName: guestInfo.value.firstName,
        lastName: guestInfo.value.lastName,
        fullName: guestInfo.value.firstName + ' ' + guestInfo.value.lastName,
        value: guestInfo.value.firstName + ' ' + guestInfo.value.lastName,
        title: guestInfo.value.title,
        company: guestInfo.value.company,
        phone: guestInfo.value.phone,
        eMail: guestInfo.value.eMail,
        index: Math.floor(Math.random() * 112114115171),
        isDeleted: guestInfo.value.isDeleted,
      })

      guestInfo.value = {
        id: 0,
        firstName: '',
        lastName: '',
        title: '',
        company: '',
        phone: '',
        eMail: '',
        index: null,
        isDeleted: false
      }

      guestModalShow.value = false
    }

    const guestHandleModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()

      if (guestInfo.value.firstName === '') {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'İsim zorunludur!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      if (guestInfo.value.lastName === '') {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Soyisim zorunludur!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      if (guestInfo.value.title === '') {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Ünvan zorunludur!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      guestSubmitModal()
    }

    const removeParticipant = (participantData) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dataInfo.value.participants.forEach((value, index) => {
            if (value === participantData) {
              value.isDeleted = true
            }
          });
        }
      })
    }

    const roomOptions = ref([])
    const getRooms = () => {
      roomOptions.value = []
      busy.value = true
      store.dispatch('store-room/fetchItems', {
        startDate: dataInfo.value.startDate,
        endDate: dataInfo.value.endDate
      }).then(response => {
        roomOptions.value = []
        response.data.data.forEach((value, index) => {
          if (value.workplaceId === dataInfo.value.workplaceId && value.isActive === true) {
            roomOptions.value.push({label: value.name, value: value.id, busyRoom: value.busy})
          }
        });
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
        editDataForm.value = JSON.parse(localStorage.getItem('userData')).id === dataInfo.value.ownerId && dataInfo.value.meetingStatusId === 'SHARED'
        reportDataForm.value = JSON.parse(localStorage.getItem('userData')).id === dataInfo.value.ownerId || dataInfo.value.meetingStatusId === 'REPORT_SHARED'

        dataInfo.value.flows.forEach((value, index) => {
          value.value = value.firstName + ' ' + value.lastName
          value.userInfo = {
            fullName: value.firstName + ' ' + value.lastName,
            profilePicture: value.profilePicture
          }

          if (value.startDate !== null) {
            value.startDate = value.startDate.split('T')[1].replace(':00.0000000', '')
          }

          if (value.endDate !== null) {
            value.endDate = value.endDate.split('T')[1].replace(':00.0000000', '')
          }

        })

        getRooms(dataInfo.value.workplaceId)
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'meeting-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const {refFormObserver, getValidationState, resetForm} = formValidation()

    watch(dataInfo, (newValue, oldValue) => {

    });

    const participantUsers = computed(() => dataInfo.value.participants.filter(item => (item.meetingParticipantTypeId === 'PARTICIPANT' || item.meetingParticipantTypeId === 'ORGANIZER') && item.isDeleted === false));
    const informationUsers = computed(() => dataInfo.value.participants.filter(item => item.meetingParticipantTypeId === 'INFORMATION' && item.isDeleted === false));
    const guestUsers = computed(() => dataInfo.value.participants.filter(item => item.meetingParticipantTypeId === 'GUEST' && item.isDeleted === false));
    const flowList = computed(() => dataInfo.value.flows.filter(item => item.isDeleted === false));

    const addParticipant = (type) => {
      if (newParticipantUserId.value === null && newInfoUserId.value === null) {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Lütfen seçim işlemini yapınız!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      let userId = 0;
      if (type === 'PARTICIPANT') {
        userId = newParticipantUserId.value;
      } else {
        userId = newInfoUserId.value;
      }

      if (dataInfo.value.participants.filter(item => item.meetingParticipantTypeId === type && item.userId === userId && item.isDeleted === false).length === 0) {
        const selectedUser = userOptions.value.filter(item => item.value === userId)[0]
        dataInfo.value.participants.push({
          meetingParticipantTypeId: type,
          userId: userId,
          id: null,
          firstName: selectedUser.firstName,
          lastName: selectedUser.lastName,
          fullName: selectedUser.firstName + ' ' + selectedUser.lastName,
          value: selectedUser.firstName + ' ' + selectedUser.lastName,
          title: selectedUser.positionName,
          profilePicture: selectedUser.avatar,
          company: '',
          phone: '',
          eMail: '',
          index: Math.floor(Math.random() * 112114115171),
          isDeleted: false,
        })

        newParticipantUserId.value = null
        newInfoUserId.value = null

      } else {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Kayıt daha önce eklenmiş!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    }

    const setOrganizer = (user) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Moderatör olarak işaretlenecektir!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, İşaretle',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dataInfo.value.participants.forEach((value, index) => {
            if (value === user) {
              if (value.meetingParticipantTypeId === 'ORGANIZER') {
                value.meetingParticipantTypeId = 'PARTICIPANT'
              } else {
                value.meetingParticipantTypeId = 'ORGANIZER'
              }
            }
          });
        }
      })
    }

    const flowEditInfo = ref(null)
    const flowModalShow = ref(false)
    const flowInfo = ref({
      id: 0,
      sortNumber: null,
      startDate: null,
      endDate: null,
      title: '',
      userInfo: null,
      index: null,
      isDeleted: false
    })

    const flowNewModal = () => {
      flowInfo.value = {
        id: 0,
        sortNumber: dataInfo.value.flows.length + 1,
        startDate: '08:00',
        endDate: '08:00',
        title: '',
        userInfo: null,
        index: null,
        isDeleted: false
      }
      flowModalShow.value = true
    }

    const flowResetModal = () => {

    }

    const flowSubmitModal = () => {
      let isAppendItem = true
      dataInfo.value.flows.forEach((value, index) => {
        if (value === flowEditInfo.value) {
          isAppendItem = false
          value.id = flowInfo.value.id
          value.sortNumber = flowInfo.value.sortNumber
          value.startDate = flowInfo.value.startDate
          value.endDate = flowInfo.value.endDate
          value.title = flowInfo.value.title
          value.userId = flowInfo.value.userInfo != null ? flowInfo.value.userInfo.userId : null
          value.firstName = flowInfo.value.userInfo != null ? flowInfo.value.userInfo.firstName : null
          value.lastName = flowInfo.value.userInfo != null ? flowInfo.value.userInfo.lastName : null
          value.position = flowInfo.value.userInfo != null ? flowInfo.value.userInfo.title : null
          value.profilePicture = flowInfo.value.userInfo != null ? flowInfo.value.userInfo.profilePicture : null
          value.isDeleted = flowInfo.value.isDeleted
          value.index = Math.floor(Math.random() * 112114115171)
        }
      });

      if (isAppendItem) {
        dataInfo.value.flows.push({
          id: flowInfo.value.id,
          sortNumber: flowInfo.value.sortNumber,
          startDate: flowInfo.value.startDate,
          endDate: flowInfo.value.endDate,
          title: flowInfo.value.title,
          userId: flowInfo.value.userInfo != null ? flowInfo.value.userInfo.userId : null,
          firstName: flowInfo.value.userInfo != null ? flowInfo.value.userInfo.firstName : null,
          lastName: flowInfo.value.userInfo != null ? flowInfo.value.userInfo.lastName : null,
          position: flowInfo.value.userInfo != null ? flowInfo.value.userInfo.title : null,
          profilePicture: flowInfo.value.userInfo != null ? flowInfo.value.userInfo.profilePicture : null,
          isDeleted: flowInfo.value.isDeleted,
          index: Math.floor(Math.random() * 112114115171)
        })
      }

      flowInfo.value = {
        id: 0,
        sortNumber: null,
        startDate: null,
        endDate: null,
        title: '',
        userInfo: null,
        index: null,
        isDeleted: false
      }

      flowEditInfo.value = null

      flowModalShow.value = false
    }

    const flowHandleModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()

      if (flowInfo.value.sortNumber === null) {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Sıra numarası zorunludur!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      if (flowInfo.value.startDate === null) {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Başlangıç zamanı zorunludur!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      if (flowInfo.value.endDate === null) {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Bitiş zamanı zorunludur!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      if (flowInfo.value.title === null) {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Başlık zorunludur!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      flowSubmitModal()
    }

    const flowRemove = (flowData) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dataInfo.value.flows.forEach((value, index) => {
            if (value === flowData) {
              value.isDeleted = true
            }
          });
        }
      })
    }

    const flowEdit = (flowData) => {
      flowEditInfo.value = flowData
      flowInfo.value = {
        id: flowData.id,
        sortNumber: flowData.sortNumber,
        startDate: flowData.startDate,
        endDate: flowData.endDate,
        title: flowData.title,
        userInfo: flowData.userInfo,
        index: flowData.index,
        isDeleted: flowData.isDeleted,
      }
      flowModalShow.value = true
    }

    const eventCancel = () => {
      if (dataInfo.value.meetingStatusId !== 'CLOSED') {
        cancelInfo.value = {
          meetingId: dataInfo.value.id,
          description: ''
        }

        cancelModalShow.value = true
      } else {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Toplantı durumu uygun değil!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    }

    const cancelInfo = ref({
      meetingId: null,
      description: ''
    })

    const cancelModalShow = ref(false)

    const cancelHandleModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      cancelSubmitModal()
    }

    const cancelSubmitModal = () => {
      if (cancelInfo.value.description === null || cancelInfo.value.description === '') {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'İptal açıklaması girilmelidir!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      store.dispatch('store/cancelItem', cancelInfo.value).then(response => {
        reFetchEvents()

        Vue.swal({
          icon: 'success',
          title: 'Başarılı!',
          text: 'Toplantı iptal edildi.',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })

      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: error.response.data.message,
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {

      })

      cancelInfo.value = {
        meetingId: null,
        description: ''
      }

      cancelModalShow.value = false
    }

    const documentHandleModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      documentModalShow.value = false
    }

    documentTableColumns.value = [
      {key: 'name', label: 'Dosya Adı', sortable: false},
      {key: 'createdUserName', label: 'Ekleyen', sortable: false},
      {key: 'isReport', label: 'Tutanak', sortable: false},
      {key: 'createdOn', label: 'Tarih', sortable: true},
      {key: 'filePath', label: 'Görüntüle', sortable: false},
    ]

    const previewFile = (filePath) => {
      window.open(getApiFile(filePath), '_blank');
    }

    return {
      busy,
      dataInfo,
      refFormObserver,
      guestModalShow,
      guestInfo,
      workplaceOptions,
      categoryOptions,
      roomOptions,
      userOptions,
      newParticipantUserId,
      newInfoUserId,
      participantUsers,
      informationUsers,
      guestUsers,
      flowModalShow,
      flowInfo,
      flowList,
      editDataForm,
      reportDataForm,
      meetingTypeOptions,
      meetingRemindingTypeOptions,
      cancelInfo,
      cancelModalShow,
      documentModalShow,
      documentTableColumns,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,
      guestNewModal,
      guestResetModal,
      guestSubmitModal,
      guestHandleModal,
      removeParticipant,
      avatarText,
      getApiFile,
      getRooms,
      addParticipant,
      setOrganizer,
      flowNewModal,
      flowResetModal,
      flowSubmitModal,
      flowHandleModal,
      flowRemove,
      flowEdit,
      eventCancel,
      cancelHandleModal,
      cancelSubmitModal,
      documentHandleModal,
      previewFile,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
@import '@core/scss/vue/libs/vue-flatpicker';

.select-size-sm {
  min-width: 100%;
}

.empty-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.button-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.scroll-flow {
  max-height: 650px;
  overflow-y: auto;
}
</style>
